import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import ContainerMain from "./reusableComponents/ContainerMain";
import { Image } from "semantic-ui-react";
import './style.css'
import ProductivityImg from "../assets/images/productivity.svg"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "50px 125px 50px 125px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    color: theme.palette.tertiary.main,
    fontWeight: 700,
    fontSize: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.defaultWhite.main,
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
    justifyContent: "space-between",
    minHeight: "70vh",
  },
  services: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  FormSection: {
    marginBottom: "20px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "10px",
    },
    paddingLeft: "50px",
  },
  ImageSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 60,
    },
    marginTop: "60px",
  },
  DashImage: {
    height: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
    },
    margin: "auto",
    position: "absolute",
  },
  captionText: {
    fontSize: 20,
    color: theme.palette.tertiary.main,
    opacity: 0.5,
  },
  button: {
    width: "160px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "5px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  anchor: {
    textDecoration: "none",
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontWeight: 900,
    fontSize: "30px",
    color: theme.palette.tertiary.main,
  },
  serviceHeader: {
    fontWeight: 700,
    fontSize: 25,
    color: theme.palette.tertiary.main,
  },
  qaSub: {
    color: theme.palette.text.main,
    fontWeight: 300,
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px 10px 0px",
    textAlign: "center",
  },
  buttonField: {
    color: theme.palette.defaultWhite.main,
    borderRadius: "3px",
    fontSize: "10px",
    padding: "8px 30px 8px 30px",
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      padding: "8px 35px 8px 35px",
    },
    "&:hover": {
      background: theme.palette.primary.main,
    },
    textTransform: "none",
    boxShadow: "none",
  },
  before: {
    boxShadow: "10px 22px 36px #AF66001A",
    top: 0,

  },
  prodButton: {
    top: -20,
    marginLeft: 10,
    textTransform: "none",
    background: "#E5E5E5",
    borderRadius: 10,
    fontWeight: 300,
    padding: "5px 45px 5px 45px",
    "&:hover": {
      background: "#E5E5E5",
    },
    [theme.breakpoints.down("md")]: {
      top: 0,
    },
  },
  prodButton2: {
    top: -20,
    marginLeft: 10,
    textTransform: "none",
    background: theme.palette.primary.main,
    borderRadius: 10,
    fontWeight: 300,
    padding: "5px 50px 5px 50px",
    color: theme.palette.defaultWhite.main,
    "&:hover": {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      top: 10,
    },
  },
  listItem: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.text.main,
    margin: 10,
  },
  listItem2: {
    fontSize: 13,
    color: theme.palette.tertiary.main,
    margin: 10,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  minWidth: {
    minWidth: 260,
    marginTop: 0,
    justifyContent: "space-around",
  },
  minWidth2: {
    minWidth: 350,
    marginTop: 0,
    justifyContent: "space-around",
  },
  ulStyle: {
    paddingInlineStart: "0px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
  },
}));

const Productivity = () => {
  const classes = useStyles();

  return (
    <ContainerMain>
      <Grid className={`Collecteaze-Prod ${classes.container}`}>
        <Grid className={`Prod-Head ${classes.qaMain}`}>
          Boosting Agent Productivity at Collection Agencies
        </Grid>
        <Grid className={classes.root}>
          <Grid className={`ProdSec  ${classes.sectionContainer}`}>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              <img className={`ProdImg ${classes.DashImage}`} src={ProductivityImg}/>
            </Grid>
            <Grid item xs={12} md={6} className={classes.FormSection}>
              <Grid className={`Before-Prod ${classes.before}`}>
                <Button className={classes.prodButton}>Before</Button>
                <Grid className={classes.column}>
                  <ul className={classes.ulStyle}>
                    <Grid container className={classes.minWidth}>
                      <Grid item xs={5}>
                        <li className={classes.listItem}>Customer Churn</li>
                      </Grid>
                      <Grid item xs={5}>
                        <li className={classes.listItem}>Checks</li>
                      </Grid>
                    </Grid>
                  </ul>
                  <ul className={classes.ulStyle}>
                    <Grid container className={classes.minWidth}>
                      <Grid item xs={5}>
                        <li className={classes.listItem}>Workload</li>
                      </Grid>
                      <Grid item xs={5}>
                        <li className={classes.listItem}>Delays</li>
                      </Grid>
                    </Grid>
                  </ul>
                </Grid>
              </Grid>
              <Grid className={`After-Prod ${classes.before}`}>
                <Button className={classes.prodButton2}>After</Button>
                <Grid className={classes.column}>
                  <ul className={classes.ulStyle}>
                    <Grid container className={classes.minWidth2}>
                      <Grid item xs={5}>
                        <li className={classes.listItem2}>
                          Customer Satisfaction
                        </li>
                      </Grid>
                      <Grid item xs={5}>
                        <li className={classes.listItem2}>
                          Employee Gratification
                        </li>
                      </Grid>
                    </Grid>
                  </ul>
                  <ul className={classes.ulStyle}>
                    <Grid container className={classes.minWidth2}>
                      <Grid item xs={5}>
                        <li className={classes.listItem2}>Digital Payments</li>
                      </Grid>
                      <Grid item xs={5}>
                        <li className={classes.listItem2}>Improved DSO</li>
                      </Grid>
                    </Grid>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`KPO-container ${classes.services}`}>
            <Typography className={classes.serviceHeader}>
              KPO Services
            </Typography>
            <Grid className={`KPO-Subhead ${classes.qaSub}`}>
              Our exceptional knowledge process outsourcing (KPO) services are designed to increase your debt collection efficiency while relieving you from all the hard work at a reasonable price.
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonField}
            >
              Know More
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ContainerMain>
  );
};

export default Productivity;

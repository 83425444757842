import React from "react";
import { AppBar, Toolbar, IconButton, Grid, Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Image } from "semantic-ui-react";
import LogoImg from "../assets/images/app_logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    background: theme.palette.defaultWhite.main,
  },
  button: {
    width: "160px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "5px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  appBarElevations: {
    boxShadow: "none",
  },
  toolbarJustifyBetween: {
    justifyContent: "space-between",
  },
  navLinksGrid: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  anchor: {
    color: theme.palette.tertiary.main,
    textDecoration: "none",
  },
}));

const LandingPageAppBar = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <AppBar
        className={classes.appBarElevations}
        color="transparent"
        position="static"
      >
        <Toolbar
          disableGutters={true}
          className={classes.toolbarJustifyBetween}
        >
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={LogoImg} />
          </IconButton>
          <Hidden smDown>
            <Grid className={classes.navLinksGrid}>
              <a href={"#products"} className={classes.anchor}>
                Products
              </a>
              <a href={"#services"} className={classes.anchor}>
                Services
              </a>
              <a href={"#aboutUs"} className={classes.anchor}>
                About Us
              </a>
              <a href={"#resources"} className={classes.anchor}>
                Resources
              </a>
              <RouterLink to="/contact" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Contact Us
                </Button>
              </RouterLink>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default LandingPageAppBar;

import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import LandingPageAppBar from "../components/LandingPageAppBar";
import ContainerMain from "./reusableComponents/ContainerMain";
import { Image } from "semantic-ui-react";
import Img1 from '../assets/images/main.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.defaultWhite.main,
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
    justifyContent: "space-between",
    minHeight: "80vh",
    zIndex: "5",
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "10px",
    },
    marginTop: 100,
    paddingLeft: "120px",
  },
  ImageSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 60,
    },
    marginTop: "60px",
  },
  DashImage: {
    height: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
    },
    margin: "auto",
    position: "absolute",
  },
  captionText: {
    fontSize: 20,
    color: theme.palette.tertiary.main,
    opacity: 0.5,
  },
  button: {
    width: "160px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "5px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  anchor: {
    textDecoration: "none",
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontWeight: 900,
    fontSize: "30px",
    color: theme.palette.tertiary.main,
  },
}));

const DashboardMain = () => {
  const classes = useStyles();

  return (
    <ContainerMain>
      <Grid id="main">
        <LandingPageAppBar />
        <Grid className={`Collecteaze-Head ${classes.root}`}>
          <Grid className={`Main-Container ${classes.sectionContainer}`}>
            <Grid item xs={12} md={7} className={`Main-section-1 ${classes.FormSection}`}>
              <Grid>
                <Typography style={{fontWeight:"400"}} gutterBottom className={`Main-Section-Head ${classes.mainText}`}>
                  THE PATH TO PEAK <span style={{fontWeight:"700"}}>PERFORMANCE <br/>AND PROFITABILITY</span>
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.captionText}>
                  Credit And Collection Solutions <br /> Built For Growth
                </Typography>
              </Grid>
              <Grid className={classes.requestDemoMarginBottom}>
                {/* <TypeWriter messages={msgs} /> */}
              </Grid>
              <Grid>
                <a
                  // href={"#virucontact"}
                  className={classes.anchor}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Get in Touch
                  </Button>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.ImageSection}>
              <img className="Main-Img" src={Img1} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerMain>
  );
};

export default DashboardMain;

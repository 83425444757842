import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Main from "../components/Main";
import Solutions from "../components/Solutions";
import Productivity from "../components/Productivity";
import Parameters from "../components/Parameters";
import Features from "../components/Features";
import WhyUs from "../components/WhyUs";
import ClientReviews from "../components/ClientReviews";
import Demo from "../components/Demo";
import QA from "../components/QA";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.defaultWhite.main,
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  return (
    
    <Grid className={classes.root}>
      <Grid>
        <Main />
        <Solutions />
        <Productivity />
        <Parameters />
        <Features />
        <WhyUs />
        <ClientReviews />
        <Demo />
        {/* <DashboardMain />
        <DashboardWhyUs />
        <DashboardFeatures />
        <DashboardStats />
        <DashboardAbout />
        <DashboardReviews id='viruhub' />
        <DashboardBlogs />
        <DashboardEmailer id='virucontact' /> */}
      </Grid>
      <QA />
      <Footer />
      {/* <UploadCVModal /> */}
    </Grid>
  );
};

export default LandingPage;

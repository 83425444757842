import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import ContainerMain from "./reusableComponents/ContainerMain";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "50px 200px 75px 200px",
    background: theme.palette.tertiary.main,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appSubName: {
    color: theme.palette.defaultWhite.main,
    fontWeight: 500,
    fontSize: "25px",
  },
  appName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "25px",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  qaSub: {
    color: theme.palette.defaultWhite.main,
    fontWeight: 300,
    fontSize: "13px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "5px 0px 5px 0px",
    textAlign: "center",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 10,
  },
  textField: {
    background: "#213156",
    color: "rgba(255, 255, 255, 0.4)",
    width: "100%",
    borderRadius: "3px",
  },
  buttonGrid: {
    width: "100%",
    height: "45px",
  },
  buttonField: {
    color: theme.palette.defaultWhite.main,
    width: "100%",
    height: "100%",
    borderRadius: "3px",
    fontSize: "12px",
    "&:hover": {
      background: theme.palette.primary.main,
    },
    textTransform: "none",
  },
  selectField: {
    background: "#213156",
    color: theme.palette.defaultWhite.main,
    width: "100%",
    borderRadius: "3px",
  },
  multilineColor: {
    color: theme.palette.defaultWhite.main,
  },
  field: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.4)",
  },
  InputLabel: {
    color: "rgba(255, 255, 255, 0.4)",
    fontSize: "13px",
  },
}));

const ReusableTextField = (props) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      variant="filled"
      size="small"
      label={props.value}
      color="secondary"
      InputLabelProps={{
        style: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "100%",
          color: "rgba(255, 255, 255, 0.4)",
          fontSize: "13px",
        },
      }}
      InputProps={{
        className: classes.multilineColor,
      }}
    />
  );
};

const Demo = () => {
  const classes = useStyles();
  const [system, setSystem] = React.useState("");

  const handleChange = (event) => {
    setSystem(event.target.value);
  };

  return (
    <ContainerMain>
      <Grid className={classes.container}>
        <Grid className={classes.qaMain}>
          <Typography inline className={classes.appSubName}>
            See
          </Typography>
          <Typography inline className={classes.appName}>
            CollectEaze
          </Typography>
          <Typography inline className={classes.appSubName}>
            in action
          </Typography>
        </Grid>
        <Grid className={classes.qaSub}>
          Get in touch & learn how Collecteaze can boost your cash flow
          performance.
        </Grid>
        <br />
        <Grid container className={classes.flex}>
          <Grid item xs={12} md={5} className={classes.field}>
            <ReusableTextField value="First Name" />
          </Grid>
          <Grid item xs={12} md={5} className={classes.field}>
            <ReusableTextField value="Last Name" />
          </Grid>
        </Grid>
        <Grid container className={classes.flex}>
          <Grid item xs={12} md={5} className={classes.field}>
            <ReusableTextField value="Email" />
          </Grid>
          <Grid item xs={12} md={5} className={classes.field}>
            <ReusableTextField value="Company" />
          </Grid>
        </Grid>
        <Grid container className={classes.flex}>
          <Grid item xs={12} md={5} className={classes.field}>
            <FormControl variant="filled" className={classes.textField}>
              <InputLabel className={classes.InputLabel}>
                What is your ERP system?
              </InputLabel>
              <Select
                className={classes.selectField}
                value={system}
                onChange={handleChange}
                color="secondary"
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Oracle eBusiness Suite</MenuItem>
                <MenuItem value={2}>Oracle Cloud ERP</MenuItem>
                <MenuItem value={3}>SAP Business One</MenuItem>
                <MenuItem value={4}>Microsoft Dynamics 365</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5} className={classes.field}>
            <Grid className={classes.buttonGrid}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonField}
              >
                Request Demo
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerMain>
  );
};

export default Demo;

import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import ContainerMain from "./reusableComponents/ContainerMain";
import { Image } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "75px 250px 75px 250px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    color: theme.palette.tertiary.main,
    fontWeight: 700,
    fontSize: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  gridContainer: {
    justifyContent: "space-evenly",
  },
  featureSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    minHeight: 250,
    alignItems: "space-around",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: "5px",
    padding: "5px 30px 5px 30px",
    boxShadow: "10px 3px 20px #00000029",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
    },
  },
  featureName: {
    color: theme.palette.defaultWhite.main,
    textAlign: "center",
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  featureDescription: {
    fontSize: "11px",
    color: theme.palette.text.main,
    fontWeight: 300,
    paddingTop: "5px",
  },
  nameFlex: {
    display: "flex",
  },
  circle: {
    height: "35px",
    width: "35px",
    border: "1px solid",
    borderColor: theme.palette.text.main,
    borderRadius: "35px",
    padding: "5px",
  },
  person: {
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  name: {
    fontSize: "12px",
  },
  position: {
    fontSize: "10px",
    color: theme.palette.text.main,
    opacity: 0.5,
    // fontWeight: 300,
  },
  GridImage: {
    padding: 5,
  },
  image: {
    width: "110px",
    height: "30px",
  },
}));

const ClientReviews = () => {
  const classes = useStyles();

  return (
    <ContainerMain>
      <Grid className={`Collecteaze-ClientReviews ${classes.container}`}>
        <Grid className={`Review-sub ${classes.qaMain}`}>What some of our clients think…</Grid>
        <Grid container className={`Review-container ${classes.gridContainer}`}>
          <Grid item className={classes.featureSection}>
            <Grid>
              <Grid className={classes.GridImage}>
                <Image
                  className={classes.image}
                  src={require("../assets/images/client_reviews.png")}
                />
              </Grid>
              <Typography className={classes.featureDescription}>
                CollectEaze impactful right out of the box, and extremely simple
                to implement. The click-ability makes the data so simple to
                maneuver, allowing us to move through conversations and
                conclusions faster.
              </Typography>
            </Grid>
            <Grid className={classes.nameFlex}>
              <Grid className={classes.circle} />
              <Grid className={classes.person}>
                <Typography className={classes.name}>Brian</Typography>
                <Typography className={classes.position}>
                  Sr. Manager, Tensor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.featureSection}>
            <Grid>
              <Grid className={classes.GridImage}>
                <Image
                  className={classes.image}
                  src={require("../assets/images/client_reviews.png")}
                />
              </Grid>
              <Typography className={classes.featureDescription}>
                CollectEaze impactful right out of the box, and extremely simple
                to implement. The click-ability makes the data so simple to
                maneuver, allowing us to move through conversations and
                conclusions faster.
              </Typography>
            </Grid>
            <Grid className={classes.nameFlex}>
              <Grid className={classes.circle} />
              <Grid className={classes.person}>
                <Typography className={classes.name}>Greg</Typography>
                <Typography className={classes.position}>
                  Sr. Manager, Tensor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.featureSection}>
            <Grid>
              <Grid className={classes.GridImage}>
                <Image
                  className={classes.image}
                  src={require("../assets/images/client_reviews.png")}
                />
              </Grid>
              <Typography className={classes.featureDescription}>
                CollectEaze impactful right out of the box, and extremely simple
                to implement. The click-ability makes the data so simple to
                maneuver, allowing us to move through conversations and
                conclusions faster.
              </Typography>
            </Grid>
            <Grid className={classes.nameFlex}>
              <Grid className={classes.circle} />
              <Grid className={classes.person}>
                <Typography className={classes.name}>Chris</Typography>
                <Typography className={classes.position}>
                  Sr. Manager, Tensor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerMain>
  );
};

export default ClientReviews;

import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import ContainerMain from "./reusableComponents/ContainerMain";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: "75px 250px 75px 250px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    color: theme.palette.tertiary.main,
    fontWeight: 900,
    fontSize: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  qaSub: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "10px 0px 10px 0px",
    textAlign: "center",
  },
  gridContainer: {
    justifyContent: "space-evenly",
  },
  featureSection: {
    minHeight: 200,
    alignItems: "center",
    marginTop: 40,
    marginBottom: 40,
    border: "1px solid",
    borderColor: "rgba(112, 112, 112, 0.36)",
    borderRadius: "5px",
    padding: "20px",
    background: theme.palette.tertiary.main,
    position: "relative",
    top: 0,
    transition: "top-ease 0.5s",
    "&:hover": {
      background: theme.palette.primary.main,
      cursor: "pointer",
      top: "-10px",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.8)",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "22%",
    },
  },
  featureName: {
    color: theme.palette.defaultWhite.main,
    textAlign: "center",
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  featureDescription: {
    fontSize: "12px",
    color: theme.palette.defaultWhite.main,
    textAlign: "center",
  },
}));

export const Parameters = () => {
  const classes = useStyles();
  return (
    <Grid className={`Collecteaze-parameter ${classes.container}`}>
      <ContainerMain>
        <Grid className={`Align-Left ${classes.qaMain}`}>Employ the Ideal Workforce</Grid>
        <Grid className={`Align-Left ${classes.qaSub}`}>
          Increase Productivity And Profitability With The Perfect Employee
          <br />
          Outsourcing Model
        </Grid>
        <Grid container className={`Parameter-container ${classes.gridContainer}`}>
          <Grid item className={` Feature-section-box ${classes.featureSection}`}>
            <Typography className={classes.featureName}>
              Highly Trained
            </Typography>
            <Typography className={classes.featureDescription}>
              Each employee receives in-depth training on the industry and
              standard operating procedures (SOPs) they’ll be supporting.
            </Typography>
          </Grid>
          <Grid item className={` Feature-section-box ${classes.featureSection}`}>
            <Typography className={classes.featureName}>Affordable</Typography>
            <Typography className={classes.featureDescription}>
              We only bill for the time needed to fulfill the scoped work – not
              idle time, overtime, vacation, training or sick time.
            </Typography>
          </Grid>
          <Grid item className={` Feature-section-box ${classes.featureSection}`}>
            <Typography className={classes.featureName}>Reliable</Typography>
            <Typography className={classes.featureDescription}>
              Our associates are held to a quality standard consistent with best
              practices in production management.
            </Typography>
          </Grid>
          <Grid item className={` Feature-section-box ${classes.featureSection}`}>
            <Typography className={classes.featureName}>Compliant</Typography>
            <Typography className={classes.featureDescription}>
              We certify our associates on the policies and SOPs they’re
              responsible for, then rectify annually or when there’s a revision
              to an SOP.
            </Typography>
          </Grid>
        </Grid>
      </ContainerMain>
    </Grid>
  );
};

export default Parameters;

import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { LocationOn, AccessTime, Twitter, YouTube } from "@material-ui/icons";
import { Image } from "semantic-ui-react";
import FooterImg from "../assets/images/footer_app_logo.svg";
import Fbicon from "../assets/images/fb_icon.svg";
import LinkedinIcon from "../assets/images/linkedIn_icon.svg"; 

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.tertiary.main,
    padding: "50px 150px 50px 150px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  anchor: {
    color: "#9391A1",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 6,
      textAlign: "center",
    },
  },
  exploreText: {
    color: "#fff",
    marginBottom: 20,
  },
  FooterLinksGrid: {
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
    marginBottom: 70,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 20,
    },
  },
  footerCopyrightContainer: {
    paddingBottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  allRightsReservedText: {
    color: "#9391A1",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  logo: {
    color: "#fff",
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: "unset",
      marginTop: 10,
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "50px",
    alignItems: "center",
  },
  flex1: {
    display: "flex",
    paddingTop: "20px",
    alignItems: "center",
  },
  flex2: {
    display: "flex",
    alignItems: "center",
  },
  typo: {
    marginLeft: 10,
    color: theme.palette.defaultWhite.main,
  },
  pb20: {
    paddingBottom: 20,
  },
  ml20: {
    marginLeft: 20,
  },
  fbIcon: {
    fontSize: 20,
  },
  footerFlex: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
}));

export const Footer = () => {
  const classes = useStyles();
  return (
    <Grid className={`Collecteaze-Footer ${classes.root}`}>
      <Grid container className={`Footer-Content ${classes.footerFlex}`}>
        <Grid item xs={12} md={6}>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={FooterImg} />
          </IconButton>
          <Grid className={classes.flex1}>
            <LocationOn color="primary" />
            <Typography className={classes.typo}>
              Lorem Ipsum dolor sit Lorem Ipsum dolor
            </Typography>
          </Grid>
          <Grid className={classes.flex1}>
            <AccessTime color="primary" />
            <Typography className={classes.typo}>+91 xxxxxxx987</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} className="FooterLinks">
          <Grid className={classes.pb20}>
            <Typography color="primary">About</Typography>
          </Grid>
          {/* <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid> */}
        </Grid>
        <Grid item xs={12} md={2} className="FooterLinks">
          <Grid className={classes.pb20}>
            <Typography color="primary">Services</Typography>
          </Grid>
          {/* <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid> */}
        </Grid>
        <Grid item xs={12} md={2} className="FooterLinks">
          <Grid className={classes.pb20}>
            <Typography color="primary">Products</Typography>
          </Grid>
          {/* <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid>
          <Grid>Lorem Ipsum</Grid> */}
        </Grid>
      </Grid>
      <Grid className={`Footer-last ${classes.flex}`}>
        <Typography className={classes.typo}>
          © 2020 Cognier. All Rights Reserved.
        </Typography>
        <Grid className={classes.flex2}>
          <Typography className={classes.typo}>Follow Us</Typography>
          <Grid className={classes.ml20}>
            <img src={Fbicon} className={classes.fbIcon}/>
          </Grid>
          <Twitter className={classes.ml20} color="primary" />
          <Grid className={classes.ml20}>
            <img src={LinkedinIcon} className={classes.fbIcon}/>
          </Grid>
          <YouTube className={classes.ml20} color="primary" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;

import React from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: "30px 140px 30px 140px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    color: theme.palette.tertiary.main,
    fontWeight: 900,
    fontSize: "25px",
  },
  qaSub: {
    color: theme.palette.defaultWhite.main,
    fontSize: "15px",
    padding: "5px 0px 5px 0px",
  },
  button: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.main,
    },
    padding: "5px 30px 5px 30px",
    fontSize: "10px",
  },
}));

export const QA = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid>
        <Typography className={classes.qaMain}>
          People Plus Platforms Increase Results
        </Typography>
        <Typography className={classes.qaSub}>
          Learn How Combining KPO And Technology Can Drive Profits
        </Typography>
      </Grid>
      <Grid>
        <RouterLink to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" className={classes.button}>
            Speak to Specialist
          </Button>
        </RouterLink>
      </Grid>
    </Grid>
  );
};

export default QA;

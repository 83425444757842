import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Image } from "semantic-ui-react";
import ContainerMain from "./reusableComponents/ContainerMain";
import CloudTechImg from "../assets/images/feature_1.svg"
import BleedingTechImg from "../assets/images/feature_2.svg"
import TranspriceImg from "../assets/images/feature_3.svg"
import HighScaleImg from "../assets/images/feature_4.svg"
import DataSecImg from "../assets/images/feature_5.svg"
import SupportImg from "../assets/images/feature_6.svg"

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: "50px 200px 75px 200px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  qaMain: {
    color: theme.palette.tertiary.main,
    fontWeight: 700,
    fontSize: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  gridContainer: {
    justifyContent: "space-evenly ",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontWeight: "bold",
  },
  bigTitle: {
    color: "#EEF2F4",
    fontWeight: "bold",
  },
  featureSection: {
    minHeight: 150,
    marginTop: 30,
    marginBottom: 30,
    border: "1px solid",
    borderColor: "rgba(112, 112, 112, 0.36)",
    borderRadius: "5px",
    padding: "15px",
    background: "rgba(255, 255, 255, 0.36)",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  featureName: {
    color: theme.palette.tertiary.main,
    fontSize: "15px",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  featureDescription: {
    fontSize: "12px",
    color: theme.palette.text.main,
    fontWeight: 300,
  },
  image: {
    height: "45px",
    width: "45px",
  },
}));

export const QA = () => {
  const classes = useStyles();
  return (
    <Grid className={`Collecteaze-Why ${classes.container}`}>
      <ContainerMain>
        <Grid className={`Feature-Head ${classes.qaMain}`}>Why use CollectEaze</Grid>
        <Grid container className={`Why-us-container ${classes.gridContainer}`}>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <Grid>
              <img className={classes.image} src={CloudTechImg} />
            </Grid>
            <Typography className={classes.featureName}>
              100% Cloud Technology
            </Typography>
            <Typography className={classes.featureDescription}>
              All you need is an internet connection, computers and headsets
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <img className={classes.image} src={BleedingTechImg} />
            <Typography className={classes.featureName}>
              Bleeding-edge Technology
            </Typography>
            <Typography className={classes.featureDescription}>
              We have the experience you need to support your operations
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <img className={classes.image} src={TranspriceImg} />
            <Typography className={classes.featureName}>
              Transparent Pricing
            </Typography>
            <Typography className={classes.featureDescription}>
              There are no hidden fees, pay as you go, through monthly or annual
              contracts
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={`Why-us-container ${classes.gridContainer}`}>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <img className={classes.image} src={HighScaleImg} />
            <Typography className={classes.featureName}>
              Highly Scalable
            </Typography>
            <Typography className={classes.featureDescription}>
              We always have agents on hand to ensure that your collection process is efficient and profitable.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <img className={classes.image} src={DataSecImg} />
            <Typography className={classes.featureName}>
              Data Security
            </Typography>
            <Typography className={classes.featureDescription}>
              GDPR compliant, ISO and PCI DSS certifications
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.featureSection}>
            <img className={classes.image} src={SupportImg} />
            <Typography className={classes.featureName}>
              We Support You
            </Typography>
            <Typography className={classes.featureDescription}>
              Dedicated Key Account Manager and 24/7 support team
            </Typography>
          </Grid>
        </Grid>
      </ContainerMain>
    </Grid>
  );
};

export default QA;

import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Image } from "semantic-ui-react";
import ContainerMain from "./reusableComponents/ContainerMain";
import SolutionsImg from "../assets/images/solutions.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: "50px 220px 50px 220px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
      padding: 20,
    },
  },
  sectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "50px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
    },
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  ImageSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 30,
      marginBottom: 50,
    },
  },
  DashImage: {
    height: 600,
    [theme.breakpoints.down("xs")]: {
      height: 400,
      width: 300,
    },
  },
  bigTitle: {
    fontSize: "25px",
    color: theme.palette.tertiary.main,
    fontWeight: 700,
    paddingBottom: 10,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  subTitle: {
    fontSize: "15px",
    color: theme.palette.primary.main,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  text: {
    fontSize: "15px",
    color: theme.palette.tertiary.main,
    fontWeight: 300,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },
}));

export const Solutions = () => {
  const classes = useStyles();
  return (
    <Grid className={`Collecteaze-Solution ${classes.container}`}>
      <ContainerMain>
        <Grid className={`Solution-container ${classes.sectionContainer}`}>
          <Grid item className={classes.FormSection}>
            <Grid item>
              <Typography className={classes.bigTitle}>
                Collections Software Solutions
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.subTitle}>
                Upgrade Your Business Affordably With Innovative Technology
                That Maximizes Insight And Profitability
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                With a performance-driven partner, you can devote your energy to growth. CollectEaze provides knowledge, guidance, and direction to help you achieve sustainable profits. We also offer affordable access to an ideal workforce that streamlines the collection process and maximizes productivity.
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.ImageSection}>
            <img className={`Solution-img ${classes.DashImage}`} src={SolutionsImg}/>
          </Grid>
        </Grid>
      </ContainerMain>
    </Grid>
  );
};

export default Solutions;
